var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
      _vm.$global.rules.required() ],"label":"Name","validate-on-blur":"","outlined":""},model:{value:(_vm.place_.name),callback:function ($$v) {_vm.$set(_vm.place_, "name", $$v)},expression:"place_.name"}}),_c('v-text-field',{attrs:{"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
      _vm.$global.rules.required() ],"label":"Translation","validate-on-blur":"","outlined":""},model:{value:(_vm.place_.translation),callback:function ($$v) {_vm.$set(_vm.place_, "translation", $$v)},expression:"place_.translation"}}),_c('v-select',{attrs:{"items":_vm.icons,"item-value":function (icon) { return icon.value; },"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
      _vm.$global.rules.required() ],"label":"Icon","validate-on-blur":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('v-layout',{attrs:{"row":"","align-center":"","no-gutters":""}},[_c('v-icon',{staticClass:"mr-3",style:({color: "currentColor"})},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(item.translation))])],1)]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-layout',{attrs:{"row":"","align-center":"","no-gutters":""}},[_c('v-icon',{staticClass:"mr-3",style:({color: "currentColor"})},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(item.translation))])],1)]}}]),model:{value:(_vm.place_.icon),callback:function ($$v) {_vm.$set(_vm.place_, "icon", $$v)},expression:"place_.icon"}}),_c('v-color-picker',{staticClass:"mb-8",attrs:{"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"show-swatches":"","mode":"hexa"},model:{value:(_vm.place_.color),callback:function ($$v) {_vm.$set(_vm.place_, "color", $$v)},expression:"place_.color"}}),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
          _vm.$global.rules.required() ],"label":"X","type":"number","validate-on-blur":"","outlined":""},model:{value:(_vm.place_.x),callback:function ($$v) {_vm.$set(_vm.place_, "x", _vm._n($$v))},expression:"place_.x"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
          _vm.$global.rules.required() ],"label":"Y","type":"number","validate-on-blur":"","outlined":""},model:{value:(_vm.place_.y),callback:function ($$v) {_vm.$set(_vm.place_, "y", _vm._n($$v))},expression:"place_.y"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }